export const classes = [
  {
    id: 1,
    name: 'Warrior',
    icon: 'class_warrior',
    metaTitle: 'Warrior WotLK Classic Talent Calculator World of Warcraft Lich Kings',
    metaDescription: 'Warrior Talent Calculator in Wrath of the Lich King Classic. ⭐ Create your best WOtLK Arms, Fury or Protection build for Leveling, ⚡ PvP & PvE.',
    specs: [161, 164, 163]
  },
  {
    id: 2,
    name: 'Paladin',
    icon: 'class_paladin',
    metaTitle: 'Paladin WotLK Classic Talent Calculator World of Warcraft Lich King',
    metaDescription: 'Paladin Talent Calculator in Wrath of the Lich King Classic. ⭐ Create your best WotLK Holy, Protection or Retribution build for Leveling, ⚡ Raid, PvP & PvE.',
    specs: [382, 383, 381]
  },
  {
    id: 3,
    name: 'Hunter',
    icon: 'class_hunter',
    metaTitle: 'Hunter WotLK Classic Talent Calculator World of Warcraft Lich King',
    metaDescription: 'Hunter Talent Calculator in Wrath of the Lich King Classic. ⭐ Create your best WotLK Beast Mastery, Marksmanship or Survival build for Leveling, ⚡ Raid, PvP & PvE.',
    specs: [361, 363, 362]
  },
  {
    id: 4,
    name: 'Rogue',
    icon: 'class_rogue',
    metaTitle: 'Rogue WotLK Classic Talent Calculator World of Warcraft Lich King',
    metaDescription: 'Rogue Talent Calculator in Wrath of the Lich King Classic. ⭐ Create your best WotLK Assassination, Combat or Subtlety build for Leveling, ⚡ Raid, PvP & PvE.',
    specs: [182, 181, 183]
  },
  {
    id: 5,
    name: 'Priest',
    icon: 'class_priest',
    metaTitle: 'Priest WotLK Classic Talent Calculator World of Warcraft Lich King',
    metaDescription: 'Priest Talent Calculator in Wrath of the Lich King Classic. ⭐ Create your best WotLK Discipline, Holy or Shadow build for Leveling, ⚡ Raid, PvP & PvE.',
    specs: [201, 202, 203]
  },
  {
    id: 7,
    name: 'Shaman',
    icon: 'class_shaman',
    metaTitle: 'Shaman WotLK Classic Talent Calculator World of Warcraft Lich King',
    metaDescription: 'Shaman Talent Calculator in Wrath of the Lich King Classic. ⭐ Create your best WotLK Elemental, Enhancement or Restoration build for Leveling, ⚡ Raid, PvP & PvE.',
    specs: [261, 263, 262]
  },
  {
    id: 8,
    name: 'Mage',
    icon: 'class_mage',
    metaTitle: 'Mage WotLK Classic Talent Calculator World of Warcraft Lich King',
    metaDescription: 'Mage Talent Calculator in Wrath of the Lich King Classic. ⭐ Create your best WotLK Arcane, Fire or Frost build for Leveling, ⚡ Raid, PvP & PvE.',
    specs: [81, 41, 61]
  },
  {
    id: 9,
    name: 'Warlock',
    icon: 'class_warlock',
    metaTitle: 'Warlock WotLK Classic Talent Calculator World of Warcraft Lich King',
    metaDescription: 'Warlock Talent Calculator in Wrath of the Lich King Classic. ⭐ Create your best WotLK Affliction, Demonology or Destruction build for Leveling, ⚡ Raid, PvP & PvE.',
    specs: [302, 303, 301]
  },
  {
    id: 11,
    name: 'Druid',
    icon: 'class_druid',
    metaTitle: 'Druid WotLK Classic Talent Calculator World of Warcraft Lich King',
    metaDescription: 'Druid Talent Calculator in Wrath of the Lich King Classic. ⭐ Create your best WotLK Balance, Feral or Restoration build for Leveling, ⚡ Raid, PvP & PvE.',
    specs: [283, 281, 282]
  },
  {
    id: 12,
    name: 'Death Knight',
    icon: 'class_deathknight',
    metaTitle: 'Death Knight WotLK Classic Talent Calculator World of Warcraft Lich King',
    metaDescription: 'Death Knight Talent Calculator in Wrath of the Lich King Classic. ⭐ Create your best WotLK Blood, Frost or Unholy build for Leveling, ⚡ Raid, PvP & PvE.',
    specs: [398, 399, 400]
  },
];