export const SeoPosts = {
  1: [
    {
      content: [
        "Warrior in WOW classic, like any of the existing classes, is simply unique. He has his own Talent trees, playing technique, his own mechanics, and strategy. It's the Warrior's talents that define his essence. Like all other characters, Warrior has three talent trees that define his essence: 'Arms', 'Protection', and also 'Fury'. We'll look at the most popular builds for Warrior in PvE and PvP.",
      ],
    },
    {
      titleContent: `Warrior Talents`,
      titleType: "h2",
      content: [
        "Warrior has always been in demand since the early classics as the only PvE tank and the strongest unit at any BG when he has a trusted healer behind him. At the same time, it was a very dynamic class (mechanic of charge or intercept), that could please its owner with big numbers in terms of damage, survivability, and one of the main features of classics - debuff that reduces the enemy's healing.",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        "Consider this build first:",
        "Some situational Skills will play an important role in certain situations, and there can be several of them:",
        [
          "If you're already in a raid group and there's a Feral Druid in it or a second Fury Warrior, you don't have to choose Rampage. You can spend points on something more useful for Fury.",
          "If you're already well geared, and you're already quite a serious Fury War, then 'Fury Berserk', as well as 'Unbridled Rage' will be useless for you. You can throw free points into other talents. If you're afraid that you won't have enough rage, it's a misconception.",
          "If your raid will have any Paladins in it, you don't need to waste points on the Commander's Voice because you don't need it.",
        ],
      ],
    },
    {
      titleContent: `PVP Talents`,
      titleType: "strong",
      content: [
        "Warrior does not have permanent control like mage or warlock. Yes, we can build control combos, but they will have significant cooldowns, and a single mistake in applying them will cost you victory. That's why your teammate should be able to either have the same tunnel pressure (e.g. DK, which will allow playing on all targets at once) or be able to intercept the third-party target and be independent from any super important defensive cooldowns at the same time.",
        "That's why we don't play with Rogues, who will be the weakest class in the arena after 2 minutes. That's why we don't play with mages, who after two blocks and Evocation become a helpless class. And for this reason, we don't play with hunters, who can't hit when the whole enemy team is sitting on them. Our partner should be a self-sufficient class that can stand up for us at key points and survive when we're standing in yet another frost nova.",
      ],
    },
    {
      titleContent: `WOTLK Warrior Talents`,
      titleType: "h2",
      content: [
        "As we can see we have improved intervene, so our partner at the right time will get -30% damage for 6s, which is a very serious save given its cd of 30 seconds. Also, given the peculiarities of the Intervention, you can replace the spell reflect glyph with the Intervention glyph, so your Intervention will block 1 attack more and you will have excellent chances to catch kill shots and mortals on yourself and not on your healer. Any pro build must have Advanced Disarm, which decreases Disarm cooldown Time by 20 seconds and increases incoming damage by 10%. Look at the build in the Talents calculator.",
        "I want to talk about Improved Spell Reflection talent. Its passive is somewhat good, but its secondary effect will only reflect the spells targeted at your group members within 20 yards range, which means it won’t be as useful on Arenas unless your healer is running after you and hiding in LoS under enemies’ pressure or burst damage. You can swap the Spell Reflection glyph for Revenge glyph. If you get focused by melee opponents then it will be the best to have. After using Revenge, your next Heroic Strike will cost no rage, effectively saving much needed rage for other abilities.",
      ],
    },
    {
      titleContent: `WOTLK Warrior Talent Tree/Trees`,
      titleType: "h2",
      content: [
        "Warrior is:",
        [
          "One of the best tanks that can take large amounts of both burst and tunnel damage.",
          "Not highly dependent on enemy target positioning, has the ability to hit just about anyone.",
          "One of the strongest pressures in the game, which is our main qualitative goal in almost all games.",
          "Low dependence on cooldowns, which makes it possible to play outlasting (knocking out cooldowns, surviving, defensive style) in order to win on mana.",
          "Possibility for strong interrupts and reduced damage from casters.",
        ],
      ],
    },
    {
      titleContent: `What Warrior spec is best for Raiding?`,
      titleType: "h2",
      content: [
        "Warriors can play as a tank and are perfectly capable of holding the enemy and leading any raid. The 'Protection' spec works to save, not for damage. There shouldn't be any difficulties with the build - it's quite simple. If anyone does not know, the role of Prot Warrior is to lead the raid in a dungeon, thereby plucking all the mobs on himself. Properly selected Tank talents allow you to keep all the blows hold agro.",
        "Undoubtedly Prot War is important for raiding because no one wants to lose and spend gold to repair the armor. Therefore, you should always be focused and well-packed and, most importantly, you should always know what you need to do at different moments of the raid.",
      ],
    },
    {
      titleContent: `What spec is best for PVP?`,
      titleType: "h2",
      content: [
        "It's important to understand the warrior's role and capabilities in the current PVP season, what he can give to the team, and what he needs from the team. This will determine the synergy and how the classes complement each other. Obviously, in a War-Mage-Priest set-up, Rogue would look a lot better than Warrior. However, you (especially newbies) should understand why. A warrior doesn't have the awesome ability to save his partner that Rogue does. The latter is aided by stuns, a 70% slow, and the ability to keep a target in one place for a decent amount of time in Arena.",
      ],
    },
    {
      titleContent: `Best Warrior spec for soloing`,
      titleType: "h3",
      content: [
        "Our class doesn't require super reaction (like Rogue or Priest), doesn't require incredible positioning (like holy paladins or restor shamans), and doesn't require precise timing. And if you don't excel at something, don't feel bad, because you can focus on certain areas of our class mechanics and abilities. Take a look at the builds in our Skills calculator.",
      ],
    },
    {
      titleContent: `Best talents for leveling`,
      titleType: "h3",
      content: [
        "Arms War, if you don't know, is the first spec of 'Arms'. From the name it is clear that you will specialize in several weapons and your results will depend on their usage. Quite a good build for its rating with pumped your improved execution. As the specialization 'Arms' is chosen, all talents are carefully selected for the type of weapons you will run in the game.",
      ],
    },
  ],
  2: [
    {
      content: [
        "Paladin in WOW classic can give stable and more consistent damage. In PvE Retribution spec, Paladin is quite a competitive class in terms of DPS and also Paladin has excellent raid buffs. It's both a versatile class and also pretty easy to handle. A lot of good Paladins can even switch to Tanks when needed since they are also quite a survivable self-healing class.",
      ],
    },
    {
      titleContent: `Paladin Talents`,
      titleType: "h2",
      content: [
        "This build in Wrath of the Lich King has its main goal to make you reach the auras master, which are the most useful abilities for a paladin. The application of all auras in PvE is very broad and to describe it all would require a separate article on Talent trees.",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        "The first variant's talent list is 11/5/55",
        [
          "Seals of the Pure.",
          "Unyielding Faith.",
          "Divine Intellect.",
          "Aura Mastery.",
          "Divine Strength.",
          "Benediction.",
          "Improved Judgments.",
          "Heart of the Crusader.",
          "Improved Blessing of Might.",
          "Conviction.",
          "Seal of Command.",
          "Pursuit of Justice.",
          "Crusade.",
          "Sanctity of Battle.",
          "Sanctified.",
          "Two-Handed Weapon Specialization.",
          "Vengeance.",
          "Divine Purpose.",
          "The art of war.",
          "Repentance.",
          "Judgments.",
          "Fanaticism.",
          "Sanctified wrath.",
          "Sheath of light.",
          "Crusader strike.",
          "Swift retribution.",
          "Righteous vengeance.",
          "Divine Storm.",
        ],
        "If you take a boss like Lana'thel during takeoff, any good Retribution paladin use shadow resistance aura. ",
        "On Sindragosa the icy breath has a strong effect on the tank, Festergut with its caustic rot leaves no chance for players who don't have enough health to survive, and Lich King with the ruthlessness of winter will just wipe everyone in the raid. Tank talents for Paladin lose to other classes, so this specialization is not mentioned. If you use your auras well, you can save the lives of your raid partners. Check the build on our Talents calculator.",
        "The next variant of WOW talents is 5/11/55.",
        [
          "Seals of the Pure.",
          "Unyielding Faith.",
          "Guardians favor.",
          "Anticipation.",
          "Divine sacrifice.",
          "Eye of an eye.",
          "Sanctified.",
          "Two-Handed Weapon Specialization.",
          "Vengeance.",
          "Divine Purpose.",
          "The art of war.",
          "Repentance.",
          "Judgments.",
          "Fanaticism.",
          "Sanctified wrath.",
          "Sheath of light.",
          "Crusader strike.",
          "Swift retribution.",
          "Righteous vengeance.",
          "Divine Storm.",
        ],
        "Here the whole layout boils down to a talent like Blessing of Sacrifice. It's one of the best defensive abilities. Imagine that for ten seconds, a third of all incoming damage to your raid members would be directed at you. The Skills calculator clearly shows exactly how you can choose your talents. ",
        "There is one disadvantage of the Skills calculator - there is no such talent as 'Divine guardian', so the player's skills will only be evaluated by those who are in your raid group. Such talent is better to use in a certain combination with other spells so that this effect won't end before its due time when there is too much incoming damage. And healers won't get a heart attack after seeing a mile down in XP, which shouldn't get any incoming damage at all.",
        "After you've leveled in all the talents that are aimed at increasing damage, you're left with six more points, for which there are two options. I'll tell you about them in more detail. These talents are mainly aimed at saving the raid and yourself.",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        "If you're a newbie and this is your first time in the arena as a Paladin, and your partner isn't too far ahead either, then I think you'll need a bigger save for your partner. To do this, remove points from 'Eye of an eye' and throw in one each in 'Blessing of Sacrifice' and 'Divine guardian'.",
      ],
    },
    {
      titleContent: `WOTLK Paladin Talents`,
      titleType: "h2",
      content: [
        "The Paladin is a versatile class since he can play different roles in both PvP and PvE. The 11/5/55 talent build is pretty much spot on, and let me tell you, it works. A lot of people say that Paladin is only a one-button player - but that's not true. Not only do you need to know how to use the basic DPS boosts, but you also need to know how to use Aura Mastery. This build is perfect, as the Paladin will feel quite free in the most difficult situations for the raid and continue to pour excellent damage on the target.",
      ],
    },
    {
      titleContent: `WOTLK Paladin Talent Tree/Trees`,
      titleType: "h2",
      content: [
        "The build for retribution paladins looks like this 5/5/55. With such a talent setup, the paladin will have the maximum amount of outgoing damage possible.",
      ],
    },
    {
      titleContent: `What Paladin spec is best for Raiding?`,
      titleType: "h2",
      content: [
        "The Paladin with the 'Retribution' specialization is considered one of the most flexible characters in WOTLK. He has access to numerous auxiliary abilities and can deal high explosive damage",
      ],
    },
    {
      titleContent: `What spec is best for PVP?`,
      titleType: "h2",
      content: [
        "The best spec in PVP is Retribution. Pretty widely used 11/5/55 talent roll and let me tell you, it pays off. Many people say that Retribution is exceptionally easy - but it's not. Not only do you need to know how to use the basic DPS boosts, but you also need to know how to use Aura mastery. This build is perfect, as Retribution Paladin will feel quite free in the most difficult moments of the raid and continue to pour excellent damage on the target in the Arena.",
      ],
    },
    {
      titleContent: `Best Paladin spec for soloing`,
      titleType: "h2",
      content: [
        "A retribution paladin is an indispensable class in all raid dungeons because it has a first-class set of buffs for the entire raid, as well as good protection in the form of armor and good survivability in the hardest battles against elite bosses.",
      ],
    },
    {
      titleContent: `Best talents for leveling`,
      titleType: "h3",
      content: [
        "The first build includes talents such as Blessing of Sacrifice, Enhanced Righteous Fury, Divine guardian and Eye of an eye. If you're going to play this build, look for a one-handed weapon with spell power and a shield. Keep the shield with you until your opponents have used up all their defensive cooldowns, then pick up your normal two-handed weapon again for Retribution.",
        "The second build provides maximum burst damage and allows you to eliminate priority targets through continuous control. The build includes the Righteous Vengeance talent, which enhances damage from seals.",
      ],
    },
  ],
  3: [
    {
      content: [
        "Hunter class in WOW classic has three specializations. They are Beast Mastery, Survival, and Marksmanship. Starting with WotLK 3.3.5, Marksmanship is the best spec for PvE, although Survival and Beast Mastery are very close if you compare them. All three specializations are fully viable. However, what sets MM apart is that it is easiest one of the listed ones.",
      ],
    },
    {
      titleContent: `Hunter Talents`,
      titleType: "h2",
      content: [
        "There are a few options on how you can place your talents. It all depends on what race you decide to play as. If you started playing as Alliance, you can leave out Focused Aim, and take the improved hunter's mark. When playing for as Horde, such a talent, as Focused Aim, will be just necessary for your Skills trees. Because in the top game you notice a significant lack of hit rating. This class lacks survivability talents however. ",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        "If you've gained the necessary soft cap, it's recommended not to take the improved hunter's mark, but to take Focused aim, which will give hit rating and you won't lose DPS on bosses in Icecrown Citadel such as Lana'thel, Festergut, etc. The skills calculator will help calculate the best option for you. ",
        [
          "'Improved aspect of the hawk' - a talent that has no QD and allows you to ignore the set of haste.",
          "'Focused aim' - you will not have problems with hit rating, as well as supports DPS.",
          "'Go for the throat' - all of the pet's abilities will be used at the expense of tone, as well as increase the damage. You can level this talent just once.",
          "'Rapid killer' - reduces the QD of 'Rapid killer' to 2 minutes, which will allow you to use it several times during the battle.",
          "'Efficiency' - this talent is not used, because during the battle with boss’s hunter does not down on the mana, and there is 'Viper Bite' - it resets the mana if necessary.",
          "In addition, in the case of the 'Silencing Shot', this talent does not affect the global cooldown, so it is added to any shots that would raise the DPS.",
        ],
      ],
    },
    {
      titleContent: `PVP Talents`,
      titleType: "strong",
      content: [
        "The main talents for PvP:",
        [
          "'Deflection'-this will give you the ability to use a two-handed weapon with a 110 attack power increase.",
          "'Disorienting Shot' - I say that the game simply does not make sense without the ramp scatter.",
          "'Go for the throat' - it will regenerate focus and will increase your pet's talents uptime.",
          "'Death Bite' - reduces the chances for the buff being dispelled, and also increases the damage on the bite.",
          "'Readiness' - nullifies the QD on talents and helps in emergency situations.",
          "'Silencing Shot' - breaks the target's cast and prevents him from casting a spell for a few seconds.",
        ],
      ],
    },
    {
      titleContent: `WOTLK [Class] Talents`,
      titleType: "h2",
      content: [
        "Basic Hunter Talents:",
        [
          "Lethal Shots.",
          "Lethal Shots.",
          "Mortal shots",
          "Mortal shots",
          "Aimed shots",
          "Improved Strings.",
          "Efficiency.",
          "Barrage.",
          "Readiness.",
          "Concussive Barrage.",
          "Combat Experience.",
          "Ranged Weapon Specialization.",
          "Piercing Shots.",
          "Trueshot Aura.",
          "Master Marksman.",
          "Master Marksman.",
          "Wild Quiver.",
          "Silencing Shot.",
          "Marked for Death.",
          "Chimera Shot.",
          "Improved Tracking.",
          "Hawk Eye",
          "Entrapment.",
          "Survival Instincts.",
          "Scatter Shot.",
          "Deflection.",
          "Survival Tactics.",
        ],
      ],
    },
    {
      titleContent: `WOTLK [Class] Talent Tree/Trees`,
      titleType: "h2",
      content: [
        "After you have collected the soft cap armor penetration on your MM hunt, then immediately change the talent points from 'Improved hunter's marque' to 'Focused Aim', after changing the talents you will immediately feel the difference in DPS.",
      ],
    },
    {
      titleContent: `What [Class] spec is best for Raiding?`,
      titleType: "h2",
      content: [
        "MM Hunt in Wrath of the Lich King in WoW has long established itself as an excellent DPS raider. The best lightning burst damage, beautiful pets, and lightning-fast reaction attract a lot of gamers. However, achieving the best performance for MM Hunt is not easy, especially for a beginner. Difficulties are mainly recruiting caps, finding good weapon, and improving the rotation. ",
      ],
    },
    {
      titleContent: `What spec is best for PVP?`,
      titleType: "h2",
      content: [
        "The MM hunt is quite a common choice among players for PVP battles, and it is a very difficult opponent in capable hands, capable of inflicting constant high damage. In Arena, a pet is an assistant to the hunter, but in the MM tree, it serves more as a control tool that prevents your enemies from dealing damage by knocking down casts, mauls, and various interfering abilities. The advantage of playing PvP as a hunter is a long-range damage, which you should always keep an eye on or the melee classes will easily take you out.",
        "A pet for a hunter is a companion at all times, which helps with damage, control, and as a bonus gives various buffs. This way, choosing a pet is a time consuming activity. Most hunters you see on BG’s go with Ravager, which can interrupt casts. But don't be too quick to pick up a Ravager as a sidekick. Pay attention to the usual-looking spider. Their ability is that they can just stun and slow your opponent down, which gives you a little head start and allows you to get away from your enemy far away. This pet will be much more advantageous because the hunter is a class that plays at a distance.",
      ],
    },
    {
      titleContent: `Best [Class] spec for soloing`,
      titleType: "h2",
      content: [
        "Even though it's hard to play MM Hunter in WoW, no Hunter would trade his character for another. It's because of its powerful DPS everyone loved MM Hunter. And the powerful damage is not for a short period, but constantly during the battle. But it is not easy to maintain a constantly high DPS.",
      ],
    },
    {
      titleContent: `Best talents for leveling`,
      titleType: "h2",
      content: [
        "The best choice is the Beast Mastery spec with the following Talent trees:",
        [
          "Lethal shots;",
          "Mortal shots;",
          "Careful aim (3);",
          "Go for the throat (1);",
          "Improved arcane shot (3);",
          "Aimed shot;",
          "Endurance training; ",
          "Trick hide (3);",
          "Unleashed fury;",
          "Aspect mastery;",
          "Ferocity;",
          "Spirit bomb;",
          "Intimidation;",
          "Bestial discipline;",
          "Frenzy;",
          "Animal Handler;",
          "Ferocious Handler;",
          "Serpents Swiftness;",
          "Cobra Strikes;",
          "The Beast Within;",
          "Longevity;",
          "Kindred Spirits;",
          "Beast Mastery.",
        ],
        "Find two copies of two-handed weapons and use different enchants on them (-50% to disarm duration and stats). This will help you out on arenas and in PvE activities. Use the Talents calculator on our website.",
      ],
    },
    {},
    {},
    {},
    {},
    {},
    {},
  ],
  4: [
    {
      content: [
        "Rogue have always been head and shoulders above the rest: thanks to Invisibility, we are always the first to choose how and with what to start a fight. That's our unusual class role - we think 2 steps ahead.",
      ],
    },
    {
      titleContent: `Rogue Talents`,
      titleType: "h2",
      content: [
        "Subtlety Rogue in Wrath of the Lich King will be unique in the way this class has the most powerful burst, and one of the shortest. Thanks to a series of spells, Rogue can take down up to 70 percent of health bar even from the most geared character. A few hits from your partner and your target is beating in agony on the sands of arena. The whole tactic is just one competent burst, which is the lion's share of the victory. Try the Skills calculator on our website.",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        "Tell you a secret, SUBTLETY Rogue is not a very promising class for PvE. Not much to offer for the raid group. The specifics of the 'Subtlety' tree is designed more for PvP, but about that below. In general – this build is completely and utterly useless in PvE. Perhaps make some hybrid options - but why bother, when the other two specializations in PvE work perfectly.",
        "As for the build, in WOW classic is the most difficult responsible moment, as each incorrectly allocated point dramatically changes the battle. The right build is the most important point, and I will show you a build that is worked out not only by my own experience but also according to statistics, mechanics, and tactics of Rogue's combat. The basis of the build on the Talents calculator is that you will be running with axes and swords.",
        "Notable Talents:",
        [
          "Ruthlessness.",
          "Cold Blood.",
          "Seal Fate.",
          "Overkill.",
          "Mutilate.",
          "Cut to the Chase.",
          "Hunger for Blood.",
        ],
        "It's always been hard enough for Rogue to deal with Plate wearing classes. Now, this issue has been resolved. In PvP you'll be able to put down any Plate within 15 seconds, if it's a Healer - it will take another 15 seconds. Even at BG, you will be able to demonstrate your power, agility, speed, and invulnerability.",
      ],
    },
    {
      titleContent: `PVP Talents`,
      titleType: "strong",
      content: [
        "In PvP, Rogue can remove absolutely any kind of control with sprint. This gives not only steep survivability when used correctly, but also mobility of movement.",
        "Damage to multiple opponents at once, and fast energy regeneration make Rogue perfect class for PvP. At the moments when there's only one last strike left, enemy tries to save himself by any means possible. With Rogue, this won't work - nothing can help your target.",
        "The cool thing is that none of Rogue's auto attacks waste energy. At the same time, the damage from them is quite significant. If you just started playing WoW, the process of leveling up your character is quite tedious, but you can quickly pump Rogue to the highest level.",
        "CoS and vanish has a long CD, so in battle, they must be used very carefully and intelligently. You should bind all your important spells and abilities to certain buttons. If you click with your mouse you won't be able to maintain a high enough DPS and won’t be able to perform a perfect stunlock combo. To achieve professionalism, you must constantly practice on arenas. It is necessary to know perfectly not only the Talent trees of your Rogue but all the classes against which you’ll play. All of these points are sure to lead you to success.",
      ],
    },
    {
      titleContent: `WOTLK Rogue Talents`,
      titleType: "h2",
      content: [
        "Follow the characteristics, and most importantly dial up the drops that come first in importance. You'll see everything from the game. If slips miss - they should be eliminated - to improve Hit rating.",
        [
          "'Kill sequence symbol' - the glyph is designed for the ability of the same name, the QD of which will be less than 45 seconds. This is what we need.",
          "'Slip Symbol' - since Rogue by tactics only works in close contact, it is not uncommon to quickly get away from incoming attacks. The ability will work for 5 seconds longer, which is a nice boost. You will be able to run away to a safe distance.",
          "'Symbol of the treacherous blow' - the advantage is that at the moment of the main attack it will trigger one of the critical attacks, then the series of blows can be extended by one more blow. Since every hit is of significant importance to Rogue, this glyph is just right.",
        ],
      ],
    },
    {
      titleContent: `WOTLK Rogue Talent Tree/Trees`,
      titleType: "h2",
      content: [
        "The role of the build will be determined by the weapon you will always raid with. Each type of weapon has its talent. This point is necessary to consider. Weapons to choose by taste: Maces, daggers, swords. If you can't get good damage in raids, it means that your character is either geared incorrectly, or you're using your talents incorrectly in raids, i.e. you have the wrong rotation.",
      ],
    },
    {
      titleContent: `What Rogue spec is best for Raiding?`,
      titleType: "h2",
      content: [
        "Rogue has a set of talents that he gravitates toward the expansion, which we call standard. However, we also offer a modification to the standard build, which may be useful in certain circumstances",
        "PvE Combat Rogue Skills trees:",
        [
          "Prey on the Weak.",
          "Hack and Slash.",
          "Combat Potency.",
          "Weapon Expertise.",
          "Savage Combat.",
          "Killing Spree.",
          "Killing Spree.",
        ],
      ],
    },
    {
      titleContent: `What spec is best for PVP?`,
      titleType: "h2",
      content: [
        "Interestingly, in PvP Muti, Rogue in Arena is usually one step ahead of his opponent thanks to his stealth. Usually, Rogue in PvP goes with DD, that is, in a set-up not DD and Healer, but exactly two DDs. It is believed that the best partner in the arena will be a DD Priest, so the build for Rogue is only for this set-up. All talents only work for full damage and a good save. In general, Combat Rogue in PvP play - is one pleasure.",
      ],
    },
    {
      titleContent: `Best Rogue spec for soloing`,
      titleType: "h2",
      content: [
        "Combat Rogue has the advantage of being able to get up close to the enemy while being invisible. This allows you to control any situation and keep everything in one hand completely. Thanks to his invisibility in the early stages of combat it's easy to see who is the winner.",
      ],
    },
    {
      titleContent: `Best talents for leveling`,
      titleType: "h2",
      content: [
        "The killing power of Subtlety Rogue requires a lot of knowledge. Mastering Subtlety Rogue requires not just patience, but also stamina, extensive PvP experience, perseverance, chemistry, professionalism, and a desire to win.",
      ],
    },
  ],
  5: [
    {
      content: [
        "Priest class has tree specializations: Discipline, Holy, and Shadow. Here it can be noted that each spec is good in its way, the main thing is to understand its purpose. Despite the controversy, each Priest build can work great in raids, as well as in PVP battles or Arenas.",
      ],
    },
    {
      titleContent: `Priest talents`,
      titleType: "h2",
      content: [
        "Spell power increases the effectiveness of all spells. Critical hit helps to heal tanks, especially when combined with inspiration talent. Critical hits also replenish mana if you have the talent of holy focus. A Priest with holy specialization usually suffices 20% critical hit.",
        "Haste in WOTLK allows you to cast spells faster but also speeds up mana consumption. Shrewdness lowers the speed requirement. Try to accumulate a minimum of 800 speed. Keep a reasonable balance between speed and critical hit. Intelligence is required for any healer, as it increases max mana. Spirit speeds up mana regeneration for Priests. Spirit channeling turns 25% of spirit into spell power.",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        "The standard build covers all your bases as a shadow priest, grabbing all of the important talents for survivability, mana sustain and damage. The build gets mana regeneration from improved spirit tap and meditation, survivability from dispersion and shadow form, big damage boosts from talents like twisted faith and shadow weaving as well as your raid utility like misery and vampiric touch.",
        "Priests who specialize in holy can adapt to any situation with good talent placement, switching up and down their ability rotation. They can be stocked with different types of spells - direct and debuff effects that work on one or more targets, capable of keeping allies from getting killed in an emergency situations.",
        "The choice of talents also depends on the situation. Blessed Resilience is suitable for those who have already gathered good equipment. Otherwise, you should take the heal upgrade.",
        "Well is not a bad ability, but it's almost impossible to get your allies to use it. If you want the well, take a point from blessed resilience, faith test, or enhanced healing.",
      ],
    },
    {
      titleContent: `PvP talents`,
      titleType: "strong",
      content: [
        "For PvP, Priest is allocated 2 builds. Shadow - 1 (more suitable for 1x1 and BG) / 2 (for arena, used by gladiator Priest with 2950 rating in 2x2. And now the build - 1 (with mana burn) 2 (build with more survivability, great for example for the 5x5).",
        "PvP skills trees:",
        [
          "Dispersion.",
          "Shadow form.",
          "Psychic horror.",
          "Improved mind blast.",
          "Misery.",
          "Unbreakable will.",
          "Improved mana burn.",
        ],
      ],
    },
    {
      titleContent: `Wotlk Priest talents`,
      titleType: "h2",
      content: [
        "Like all casters, our 3 main stats are spell power, speed rating, and critical hit rating. Spirit and intelligence also affect our DPS but to a lesser extent. Here are the theoretical utility values of all stats expressed in spell power. 1 point of each stat has a theoretical utility value:",
        [
          "Spell power = 1 spell power",
          "Critical hit rating = 0.76 spell power",
          "Haste rating = 0.98 spell power",
          "Hit rating = 1.88 spell power (unless you take a marksmanship drop, more on that later)",
          "Intelligence = 0.22 spell power",
          "Spirit = 0.59 spell power",
        ],
      ],
    },
    {
      titleContent: `Wotlk Priest talent tree/trees`,
      titleType: "h2",
      content: [
        "Wotlk Priest has two long-established types of rotation. The first option is the most widely used, as raids need more stats in numbers than in good deeds.",
        "In raids on 25, everyone is constantly trying to use the shield, and those who are seriously down, pour confession. At tens also need to use shields on raids, and in parallel burn flashcards on others with confessions. Prayer goes in rotation in an emergency situation.",
        "The second option is a bit more complicated. At critical moments, when the raid has very little hp left. in addition to heal you also need to use shields, then go like this: try to use shield on raid, not wasting mana especially, the main thing to keep powerful heal on standby, so that at critical moments you can be ready to save the raid from a wipe.",
      ],
    },
    {
      titleContent: `What Priest spec is best for raiding?`,
      titleType: "h2",
      content: [
        "Standard build for 'Discipline' includes defensive talents from 'holy' - like spell protection and prayer of despair. Going deeper into 'holy' is not recommended. The build is designed for heroic raids, in which there are also other healers.",
        "The second build is designed for players who like the mechanics of the upgrade. It is suitable for mastering normal raids, as well as dungeons, where there is usually no second healer in the group. The upgrade symbol is mandatory in this build. Use the skills calculator to check the build. ",
      ],
    },
    {
      titleContent: `What spec is best for PvP?`,
      titleType: "h2",
      content: [
        "PvP Priest is one of the most interesting, dynamic, and aggressive healers on arena. Priest is the only class in the game that can dispell both magic debuffs from allies and buffs from opponents.",
      ],
    },
    {
      titleContent: `Best Priest spec for soloing`,
      titleType: "h2",
      content: [
        [
          "A Priest is considered a non-mobile class and can only move quickly using nitro boots. If you want to deal with as much damage as possible, choose your location carefully so you don't waste time running over.",
          "Merging with darkness is a great defensive cooldown. Used in time, it allows you to ignore dangerous boss mechanics.",
          "Priest specializing in Shadow can cast healing spells if necessary. Customize the raid interface to see which allies need your help.",
          "In particularly dynamic battles, you can add the word of darkness to the rotation: death. You can also use an all-consuming plague on the move, even if the effect hasn't yet dissipated (the plague deals some damage when applied).",
          "Don't forget that Priest is a hybrid class. Get ready to switch specs in raids so you can help your allies with difficult bosses.",
        ],
      ],
    },
    {
      titleContent: `Best talents for leveling`,
      titleType: "h2",
      content: [
        "Priest, since wow classic, has been one of the best healer classes. Priest is valued in arenas and PvE primarily for their useful talents, buffs, as well as ability to dispel magic effects and debuffs. In PvP, Priest is a very tenacious class, as in the discipline spec and the shadow, as well as dealing  very good damage to the enemy. You can always compare your chosen build in the talent calculator. ",
      ],
    },
  ],
  7: [
    {
      content: [
        "Shaman in WOW classic has three Talent trees - the so-called build. Each player has the right to choose not only the tree but also those talents, which together turn the forces of nature against your opponent. We'll look at the builds of Shaman in all aspects of the WoW, as the build is the basis that is laid down in the character, and thanks to which you will shine on the battlefield.",
      ],
    },
    {
      titleContent: `Shaman Talents`,
      titleType: "h2",
      content: [
        "Shamans were originally only available to the Horde. Starting with the TBC expansion, shamans are available for both factions. Representatives of the specialization call upon the primordial elements and use them to quickly destroy priority targets. Calling upon the spirits of water, fire, air, and earth, shamans establish totems to provide allies with reliable support.",
        "In the Wrath of the Lich King PvP activities, Horde and Alliance shamans are available to different races with curious abilities. For example, the best race for Restoration from Alliance side is considered to be Draenei, the best race from Horde - Orcs.",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        "Worth highlighting two possible builds for the Restor Shaman in WOTLK.",
        "The first build can be considered generic, as you have to run it in 90% of cases.",
        [
          "Improved Healing Wave.",
          "Tidal Focus.",
          "Improved Water Shield.",
          "Healing Focus.",
          "Tidal Force.",
          "Restoration Totems.",
          "Healing Way.",
          "Natures Swiftness.",
          "Focused Mind.",
          "Purification.",
          "Mana Tide Totem.",
          "Cleanse Spirit.",
          "Blessing of the Eternals.",
          "Improved Chain Heal.",
          "Natures Blessing.",
          "Ancestral Awakening.",
          "Earth Shield.",
          "Improved Earth Shield.",
          "Tidal Waves.",
          "Riptide.",
          "Ancestral Knowledge.",
          "Thundering Strikes.",
          "Improved Shields (Enh).",
        ],
        "The second build, it makes sense to use in raids that do not deal strong damage very often. Taking the talent Weapons of the elements gives a shaman gain +45SP.",
        ["The same talents as in the 1 build +Elemental weapons."],
      ],
    },
    {
      titleContent: `PVP Talents`,
      titleType: "strong",
      content: [
        "It's no secret that Elemental Shaman's PVP build is fairly straightforward, but it's still playable in PVP. Elemental Shaman is a serious opponent that can take you out in seconds at a decent distance, but one professional build is not enough. There are a lot of secrets, strategies in pumping, and in-game techniques, which you should also get acquainted with. Use the Talents calculator on our website to choose the best build for yourself. ",
        "Defensive WOW talents in Arena: Fortress (5/5 increases stamina by 10%), Elemental Defense (reduces damage by 6%), and Fury of the Shaman (active ability, reduces incoming damage by 30% and replenishes mana).",
        [
          "A must-have talent is Frozen Power, which adds a freezing effect to Ice Shock.",
          "A mobility bonus is gained by Improved Feral Spirit, which allows you to take the Ghost Wolf guise instantly. In the Ghost Wolf guise, the shaman can quickly get to cover and maintain proper distance to the target.",
          "The power of the earth is also a must. Totem of Shackles of the Earth talent with a 100% probability removes the effects of shackling from the shaman and his allies.",
        ],
      ],
    },
    {
      titleContent: `WOTLK Shaman Talent Tree/Trees`,
      titleType: "h2",
      content: [
        "Stormstrike is the most important ability of the Enhancement specialization rotation which deals the majority of its damage. This ability works instantly and its mechanics are similar to Paladin's Crusader Strike. Lava Lash is another instant spell that is often used in rotation to deal damage. The third component of the rotation is Feral Spirit. This ability summons wild wolves that fight at the shaman's side.",
      ],
    },
    {
      titleContent: `WOTLK Shaman Talents`,
      titleType: "h2",
      content: [
        "Shamans with Elemental specialization deal good burst damage and predictable sustained damage with a fairly high-speed rating. Near the end of the add-on, Elemental is inferior to the other spellcaster specializations (assuming equal equipment levels). Nevertheless, if you carefully study the rotation, you can deliver competitive damage, thus securing a place in the raid. The shaman is a hybrid class, capable of dealing damage to enemies and boosting allies. The Skills calculator helps you determine the right talents for your playing style. ",
      ],
    },
    {
      titleContent: `What Shaman spec is best for Raiding?`,
      titleType: "h2",
      content: [
        "Restoration Shaman is one of the easiest classes to play in the game's PvE content. When playing as a Restoration shaman, player has to press three buttons. On one hand, it may seem boring, but this also has its advantage. By the same token, the chainmail worn by shamans is usually not the target of many players, unlike plates or leather. Having a buff like Heroism and selected Skills trees makes the shaman very useful when doing dungeons and raids.",
      ],
    },
    {
      titleContent: `What spec is best for PVP?`,
      titleType: "h2",
      content: [
        "Typically, in PvP, shaman has to selectively eliminate priority opponents with explosive damage, so his rotation is designed for a single target. However, the rotation should not be seen as a fixed sequence of actions. In PvP, unlike in PvE, rotation is a plan of action for a specific situation (attack, reaction to enemy behavior, retaliatory strike, preventive strike, and so on). Player must act on the go and react quickly to opponents actions.",
      ],
    },
    {
      titleContent: `Best Shaman spec for soloing`,
      titleType: "h2",
      content: [
        "Shaman is the only healer that has a low-cooldown ranged spell interrupt. Shaman is more susceptible to control however. But there are plenty of opportunities to avoid getting under control. Therefore, the peculiarities of the game with this class are to avoid dangerous moments. If you don't get shocked on time, put the grounding totem, and get hex in time, you won't be able to heal the damage in the current timeframe of the game.",
      ],
    },
    {
      titleContent: `Best talents for leveling`,
      titleType: "h3",
      content: [
        "Standard build simplifies mana management, enhances abilities, and gives bonuses to critical hits. Elemental's rotation consists of Lava Lash and Chain lighting, most of the damage goes from nature magic. Mandatory talents for Elemental are Eye of the Storm, which reduces the delay in spell-reading while taking damage, and Inexorable Storm, which allows you to replenish mana while reading spells.",
        "Unforgiving Storm eliminates one of the major disadvantages of specialization which is excessive mana consumption. Storm, Earth, and Fire combined with Lightning Conquest allow you to read Chain lighting without restriction and shortens Lava Lash's cooldown.",
      ],
    },
  ],
  8: [
    {
      content: [
        "Mage in WOW classic can defeat almost any opponent. It is necessary to accumulate some experience for this however, since mage is rather hard class to play as. Mage is good because he has a lot of control, awesome damage, excellent mobility, and first-class protection in the form of shields. If you choose the right Talent trees for PVP, many simply cannot come close to mage, as he can drain his target in a few seconds. The main and serious opponent for the Mage in PvP is only Rogues. ",
      ],
    },
    {
      titleContent: `Mage Talents`,
      titleType: "h2",
      content: [
        "By choosing the most optimal WOW talents, mage will get a very high HP even when you don't high item level. I want to add that this build is perfect for PvP arena as well. I recommend using Macros for Mage, and if you are a dedicated raider, then use raid addons.",
        "The 'Blast Wave' talent is necessary because there are many situations in the game, where you need to interrupt your enemy cast, and our tanks are always busy, when we are doing raids or dungeons. Also, this talent can bail you out in various situations. But if it still does not suit you, then level up 'Protection from Ice' for one point. Try our Talents calculator to play with different combinations. ",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        "The Fire Mage in PvE segment of Wrath of the Lich King has several different build options. Moreover, each of the builds is good in its way. These builds, as seen on the Skills calculator, allow you to approach the Fire Mage with great subtleties.",
        "What we take from Frost tree for Arena:",
        [
          "Frostbite.",
          "Ice Floes.",
          "Ice Shards.",
          "Precision.",
          "Piercing.",
          "Icy Veins.",
          "Frost Channeling.",
        ],
        "In PvE, the most subtle and thoughtful build would be the 'Faster Frost Build' or FFB. With this build, you'll be giving out the most powerful DPS not only when you have good gear, but also at the initial stage of the game.",
        "It's not uncommon for Fire mage to have to interrupt bosses' casts in raids, and also to be a sort of safeguard in critical moments of the battle. If you're still opposed to it, you can throw a point into 'sFrost Warding's - Frost tree. In the same way, this build is good because it allows not to spend mana too much, which is quite important for Frost Mages in the early stages of the game.",
      ],
    },
    {
      titleContent: `PVP Talents`,
      titleType: "strong",
      content: [
        "There are several variations of PVP mage specs, specializing in different areas, but there's usually little room to customize to your style of play. We suggest the optimal spec for 3s and something more specialized for 2s. This can be adapted for your needs.",
        "Frost Tree + Arcane:",
        [
          "Arcane Subtlety.",
          "Arcane Focus.",
          "Magic Absorption.",
          "Arcane Concentration.",
          "Spell Impact.",
          "Magic Attunement.",
          "Focus Magic.",
          "Torment the Weak.",
          "Improved Counterspell.",
        ],
      ],
    },
    {
      titleContent: `WOTLK Mage Talents`,
      titleType: "h2",
      content: ["WOTLK Mage Talents"],
    },
    {
      titleContent: `WOTLK Mage Talent Tree/Trees`,
      titleType: "h2",
      content: [
        "Before you create a fire mage, you need to make the right choice of race, which will contribute to the increase of DPS in raids. Fire mage is one of the best damage dealers in WOTLK, and to avoid making mistakes when choosing a race, we selected the most suitable: Alliance - Humans and Dwarves, Horde - Trolls.",
        [
          "Humans - the most popular race among players in WoW, humans have extremely powerful racial ability usable in PvP and passive skill that increases your Spirit secondary stat.",
          "Dwarves - for mages this is a great choice because of their racial 'Stone Form' ability.",
          "Trolls are the only true choice for the Horde since their racial ability allows for a 20% increase in casting speed. In my opinion, this is one of the biggest and most OP racials in WOTLK content.",
        ],
      ],
    },
    {
      titleContent: `What Mage spec is best for Raiding?`,
      titleType: "h2",
      content: [
        "Arcane mage is a mage speced into Arcane tree, as the name suggests. Arcane is another powerful spec for mages, capable of delivering a huge DPS in raids. Arcane mages are very popular among players who have not collected BIS gear yet, because in the arcane spec damage is more tangible than in the fire with a low gear, they are mobile, they have fairly easy rotation, have high steady damage, and with the right layout of talents is useful for raid with Witchcraft intelligence buff, light protective cooldowns. Let's take a closer look at all the subtleties of playing as an arcane mage.",
      ],
    },
    {
      titleContent: `What spec is best for PVP?`,
      titleType: "h2",
      content: [
        "The 'Fire' specialization is not popular in PvP, unlike the 'Frost' specialization. Nevertheless, 'Fire' has a huge potential and can compete with 'Frost' if handled correctly. 'Frost' is the best mage specialization for PvP. Frost mage can single-handedly change the course of battle and make the lives of opponent’s hell through constant slowdowns.",
      ],
    },
    {
      titleContent: `Best Mage spec for soloing`,
      titleType: "h2",
      content: [
        "It's commonly believed that a pure Frost build has no merit because it doesn't give the Mage all the extra options he needs. With the following build, you'll be able to make both bursts and powerful attacks with confidence. Since our Frost Mage PvE guide will be based on all abilities of the class, we decided to offer you the optimal variant of the build, in which the gameplay will become the most exciting and comfortable.",
        "In this build, we added some talents from arcane magic, as the Frost Mage build as it was originally built does not provide the power that can be achieved with a hybrid. Pure Frost Mage is hard to play, because mana is running out, and that's why you have trouble with damage. Our build won't let that happen.",
      ],
    },
    {
      titleContent: `Best talents for leveling`,
      titleType: "h3",
      content: [
        "20/0/51 build is very popular now. With this build we take many of the necessary talents, of course, in the arcane tree, but still do not forget about the Frost tree. There are several variations of this build for different setups. Bursting with Frost Mage is quite a complicated thing and it's almost impossible to become a top without certain skills.",
      ],
    },
  ],
  9: [
    {
      content: [
        "Builds for 'Destruction' in WOW classic are characterized by high durability and a large number of auxiliary abilities, including curses (Curse of Cunning and Curse of the Elements) and boosts for allies (Soul Stone and Health Stone) talents. ",
      ],
    },
    {
      titleContent: `Warlock Talents`,
      titleType: "h2",
      content: [
        "In this guide, we will tell you how to play Warlock in duels, battlegrounds and arenas. Warlock with specialization 'Destruction' is a damage dealer with explosive damage, using spells of fire and shadow schools of magic.",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        "Destruction Warlock doesn't have a specific rotation at the moment but prioritizes Skills trees. Most players go like this: Corruption - Immolate - Chaos Bolt - Devastation - Conflagrate.",
        "You should always refresh your debufs when they run out. Different dots should be used according to the situation: cast Curse of the Elemental if it's more than 1 minute to the end of the battle. Otherwise use Doom. Curse of Agony should be maintained.",
        "Always, after finishing try to refresh Immolate, but don't cut off the last tick. Conflagrate and Chaos Bolt should be used when the cooldown is over. The rest of the time spam Devastation. Corruption is only used when defecting.",
      ],
    },
    {
      titleContent: `PVP Talents`,
      titleType: "strong",
      content: [
        "In the Wrath of the Lich King expansion Affliction spec has two viable builds. First build focuses on auxiliary effects (slowdowns, global cooldown reduction), and the second build is for full-scale damage.",
        "In both builds there are talents from the 'Demonology' tree. This includes increased efficiency of Health Stones, Demonic Ties for extra stamina, Kinship of Souls, redirecting 20% of damage received by Warlock to his demon, and the Power of Abomination, allowing to adapt to different combat conditions.",
        "Fel Domination reduces the time it takes to summon demons by 0.5 seconds and, when combined with Master Summoner, allows you to summon demons almost instantly. One of the strongest talents of the build is Pandemic, it allows Corruption and Haunt to deal critical hits with double damage and increases the extra damage when Haunt critically hits by 100%. Try the Skills calculator on our website to try out the build. ",
      ],
    },
    {
      titleContent: `WOTLK Warlock Talents`,
      titleType: "h2",
      content: [
        "Also available in Affliction are the standard spells for creating Soul Stones and Health Stones. The guide is relevant for the World of Warcraft Wrath of the Lich King version. In this guide, we will tell you how to play Warlock with Affliction in duels, on Battleground, and Arena. We also give recommendations on how to choose equipment, gems and enchancements, describe the rotation and give useful tips on how to play in different situations.",
      ],
    },
    {
      titleContent: `WOTLK Warlock Talent Tree/Trees`,
      titleType: "h2",
      content: [
        "'Affliction' is a specialization that actively uses periodic damage effects, curses, and spells to steal resources (health and mana). 'Affliction' provides high character strength and access to powerful abilities for damage and defense (including the Curse of Cursing and the Curse of the Elements).",
      ],
    },
    {
      titleContent: `What Warlock spec is best for Raiding?`,
      titleType: "h2",
      content: [
        "Spec has a ton of perks, some of which are discussed below:",
        [
          "Drain Soul talent, which periodically replenishes XP",
          "The talent Drain Soul, which restores mana to the members of your raid, also replenishes the mana of Warlock himself, allowing most bosses not to use Life Tap at all, thus wasting precious GKD on mana replenishment, thereby increasing your DPS.",
        ],
        "Dynamic Bosses Speck. This spec doesn't have any of the benefits I mentioned above, BUT it does have the talent of Unholy Power, which increases damage of your pet by 12%, which is better on dynamic bosses when you can use mana management without any loss of DPS. Better test the build in Talents calculator. ",
      ],
    },
    {
      titleContent: `What spec is best for PVP?`,
      titleType: "h2",
      content: [
        "54/17/0 is the standard Warlock build - Spells with Embrace of Darkness.",
        "And this 54/17/0 build is no longer a standard even though it seems like the number of talents invested in different trees are the same. Personally, I think Shadow Embrace is a bit overrated by players, but any build is quite a subject for discussion. I believe that since it's usually a 3 vs 3 Warlock team played with classes that have an ability that reduces the effectiveness of the healing they receive, Embrace of Darkness isn't that important.",
        "In general, feel free to arrange Talent trees as you want, but still, the 54/17/0 build is the perfect build for Affliction Warlock.",
        "Regarding whether it's Speed or Critical Ranking, it's again quite negotiable. Most people prefer to use Speed for Warlock. Speaking about me, I prefer to use Speed like others along with Char Stone. Everyone decides for themselves whether they just want to buy all the Speed accessories or also put in Speed stones on top of that. ",
        "I usually use Demonic Armor first, but then switch to Demonic Aegis if the situation calls for it. For example, when playing against Rogue/Mage/Priest, the most important thing is to avoid damage that they do to me at first, then when the damage is less, I can switch to Demonic Aegis. So, whenever the enemy is not focused on you, use Demonic Aegis.",
      ],
    },
    {
      titleContent: `Best Warlock spec for soloing`,
      titleType: "h2",
      content: [
        "This spec has the most AOE damage, in which Warlock simply has no competitors. After all, knocking out DPS of 20 - 30k does not require a lot of button pressed, and this is a major advantage. It requires only spamming 'Seed of Corruption' on the pack of 6 - 11 mobs while constantly cleaning trash. In addition, there is little trick, allowing to increase your DPS. After the pool it is necessary to immediately use the metamorphosis, then make a demonic jump in the pack, where there are monsters, use the sacrificial fire, and again start spamming Seed of Corruption. Just before the metamorphosis, you can use a portion of speed, but in this case, all mana will fade away in 10 seconds - this must be done carefully.",
        "All of this is applicable if your tank is simply unkillable and you're confident in it, or when the raid has excellent DPSers and the number of mobs is at least 5.",
        "Warlock in Demonology gets maximum DPS when you need it - at the stage of finishing off a boss with about 35% of his HP left.  It's all thanks to Decimation, which boosts your BP by 2.5 times.",
        "Another good thing about Demonology is that it's the most powerful buff for casters and healers. A good Warlock gives a raid between 300 and 400 spell power. When Felguard is summoned, the buff will be suspended almost constantly during the battle thanks to the Demonic Pact talent.",
      ],
    },
    {
      titleContent: `Best talents for leveling`,
      titleType: "h3",
      content: [
        "3/17/51 is the standard build for Destruction Warlocks with 3 talent points invested in Suppression for Mettle. Normally Warlock with this build uses Felhunter for Arena and Felhunter or Voidwalker for battlegrounds. Because of the change in mana restoring abilities, which now only restores mana to the player in the arena and not to the entire group, I don't think it's appropriate to put talent points into Soul Absorption.",
        "0/20/51 is the second standard Warlock Destruction build, where the 3 talent points spent in the last build are spent on Improved Succubus, so Warlock with this talent allocation usually uses it for PVP.",
        "You can also transfer 2 talent points from Improved Healthstone to Improved Health Funnel if you need it.",
      ],
    },
    {},
    {},
  ],
  11: [
    {
      content: [
        "Druids in Wrath of the Lich King are a rather limited class, available only to two races who are closely related to nature. Both Alliance and Horde have one option when it comes to race. Alliance players can choose a night elf, and Horde players can play as taurens. Both races are pretty identical in terms of strength, considering that neither offers any racial bonuses that benefit the druid healer.",
      ],
    },
    {
      titleContent: `Druid Talents`,
      titleType: "h2",
      content: [
        "Feral Druids in WOW classic who choose Cat Talent trees will face a very difficult rotation in Wrath of the Lich King, as well as the huge amount of training required. However, they will also get a high position on the damage counters and many guaranteed invitations to adventure after mastering the specialization, both in dungeons and raids. Tank talents and hit WOW talents of Druid are inferior to other classes, so the Balance Druid remains.",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        [
          "58 points to the Balance branch. Skip Moonglow, Natures Majesty, Brambless< Dreamstate> Owlkin Frenzy, Gale Winds.",
          "Improved Mark of the Wild.",
          "Furor.",
          "Mater Shapeshifter.",
          "Omen of Clarity.",
          "Natural Shapeshifter.",
        ],
        "Scatter the three free skills to your taste and preference.",
        [
          "'Typhoon' - a lot of players argue about the usefulness of this ability. Of course, if it's used incorrectly and at the wrong time, it gets in the way of raids and can even do quite a bit of damage. This ability is important, especially if you're an 'Owl' alone in a raid. 'Typhoon' knocks down casts - this is the main purpose of this ability.",
          "'Force of Nature' - in this talent usually all players throw in their remaining skills. During the carefree passage through the underworld, the Balance Druid deals about 50k damage, and that's not bad enough. It is the DPS score that has become the most popular, and everyone is pumping in all three points here.",
          "'Raging Owl' is also a popular talent. Being in a raid on bosses, when dealing AoE damage, this ability very often starts to fail. On Lady, during the second phase, the proc hangs almost constantly with two of the three skills rolled in.",
          "'Strong Wind' - this talent has no popularity whatsoever. In Icecrown Citadel it's useless, the only time it can be used is in the dungeon on the boss Anubarak on the twenty-five.",
        ],
      ],
    },
    {
      titleContent: `PVP Talents`,
      titleType: "strong",
      content: [
        "Balance Druid can play in one of three available builds. Each build has its purpose and disadvantages. The builds reveal the hybrid potential of the class and can be modified if desired. The first build includes talents from the 'Healing' tree, the choice of specific talents depends on the style of play you choose.",
        "This build includes Omen of Clarity and Dexterous Shapeshifter. Natural Born Werewolf 3/3 makes it easier to manage your mana. The build lacks Tension, so the druid cannot replenish mana while casting spells.",
        "To compensate, this build uses the talents Improved Moon Owl Appearance and Frenzy of the Owl to increase the damage dealt in the Moonkin form. The build also increases the range of the Deathstorm. Overall, the build has both damage and defense talents. Try experimenting with the Talents calculator.",
      ],
    },
    {
      titleContent: `WOTLK Druid Talents`,
      titleType: "h2",
      content: [
        "PvP battles requires a different approach than PvE. Players are required to think on the move, make quick decisions and adjust to circumstances. In PvP, there are no scripted scenes, and you can expect any, even the most bizarre actions from your opponents. Nevertheless, their behavior can be well predicted based on the available balance of power.",
        "Druid is a hybrid class known for its incredible flexibility. He can deal damage, controls enemies, cast negative effects on them, and heal allies. Druids with Balance specialization specialize in explosive damage, and they have various tools to control enemies. Druids can also heal themselves and their allies on demands.",
      ],
    },
    {
      titleContent: `WOTLK Druid Talent Tree/Trees`,
      titleType: "h2",
      content: [
        "Druid is a hybrid class, capable of adapting to different circumstances, a fact reflected in all builds. Use our Skills calculator to see for yourself.",
      ],
    },
    {
      titleContent: `What Druid spec is best for Raiding?`,
      titleType: "h2",
      content: [
        "Druids are called the guardians of Peace for a reason, meaning they are the best defenders of Azeroth. Balance Druid has long been respected as one of the best Ranged DPS’ers for PvE battles. No well-assembled raid can do well without Balance Druid. Playing as a Druid in the 'Balance' spec is quite interesting because the whole force of nature lies in the forms that Druid Balance can change during the battle",
      ],
    },
    {
      titleContent: `What spec is best for PVP?`,
      titleType: "h2",
      content: [
        "Feral Druids take a vicious cat form in PVP to stalk their enemies and ambush them with deadly attacks. Feral's specialization includes stealth elements and very powerful Skills to constantly damage, jumping from one target to another. This is especially useful on Arenas.  Feral Druids are some of the deadliest and most ferocious enemies to face in a PvP scenario.",
      ],
    },
    {
      titleContent: `Best Druid spec for soloing`,
      titleType: "h3",
      content: [
        "'Balance' is a caster specialization designed to deal explosive damage with arcane magic and nature magic. Druid is considered to be a hybrid class - in addition to dealing damage, it can heal itself and allies, control opponents, and inflict negative effects on them.",
      ],
    },
    {
      titleContent: `Best talents for leveling`,
      titleType: "h3",
      content: [
        "Best use of PVE Talents. Important traits for Balance Druid 3.3.5 PvE:",
        [
          "Marksmanship is an important component of Balance Druid. Different factions have their marksmanship cap. In the Horde cap is 263 units, and the Alliance has 237, provided that in the stat or raid is present Draenei.",
          "Speed is also very important. Given that the Balance Druid is a Ranged DD, a Softcap speed of 585 units is a must. There is one more condition: if there is a Shaman in the raid, who has the 'Wrath of the Air' totem, then the speed cap for Balance is reduced to 401 units.",
        ],
        "Every aspect of the game will directly affect not only your Damage but also your DPS, which is pumped up by the different ways and skills that are inherent to the Balance Druid. All you have to do is learn how to use them properly.",
      ],
    },
    {},
    {},
  ],
  12: [
    {
      content: [
        "Death Knight in WOW classic is quite a difficult class, but by learning to fight as a Death Knight in the Unholy or Frost tree you can become an unbeatable rock for many classes in both arena and BG’s. Many are saying that DK’s playstyle in PvP is just spamming coils and summoning gargoyles. But if you’ll be doing just that, you’ll most definitely lose to more skilled opponents. You need to learn how to to properly use the abilities, you need to learn the mechanics of the class, when to use certain abilities under which circumstances.",
      ],
    },
    {
      titleContent: `Death Knight Talents`,
      titleType: "h2",
      content: [
        "There is one combat strategy for DCs in Lich King that has been practiced for years and is suitable for arena battles. It's all about finishing your opponents with a single burst and making you the dominant player in the arena. Try the Talents calculator on our website to discover your best build.",
      ],
    },
    {
      titleContent: `PVE Talents`,
      titleType: "strong",
      content: [
        [
          "Toughness - strengthens our armor, which we don't need (since we don't have a blade fence, and we ideally shouldn't get hit) and reduces the slowdown time. ",
          "Icy Reach - can come in handy for certain fights, if your aim is to kite your target. Extra 10 meters will allow you to throw your spells from a comfortable distance. ",
          "Lichborne – can be quite handy in both PvP and PvE. Immune to fear and sleep effects, as well as increased leech is extremely useful on arenas. However, fear immune can also be used on certain bosses in dungeons and raids.",
          "Frigid Dreadplate - reduces the chance of the enemy hitting you in close combat. ",
          "Lethal Cold - seemingly 2 extra crits every half a minute. However, it takes gcd away, and we already have a lot to squeeze, and that's a big profit. ",
          "Chilblains - AOE fights in the entire LK, where it would be necessary to kite mobs, no. Pvp-talent.",
          "Deathchill - similar to permafrost.",
          "Improved Frost Presence - equal to 8% stamina for us. Stamina does not increase our DPS, so we don't need the talent.",
          "Acclimation - point spells to hit, again, should only tank.",
        ],
        "With some talents there may be questions:",
        [
          "Killing Machine - these are the WOW talents, because of which crits are not good for us. Attacking in close combat, you have a chance to get the Killing Machine buff, which increases the chance of a critical hit with ice strike or howling wind abilities up to 100%. It has a PPM system (Proc per minute), which means that there can be no more than a certain amount of procs per minute. This number of procs is determined by how much the talent is leveled. Talent gives 2-4-6-8-10 procs per minute, depending on the level of this talent.",
          "Rime - when using destruction, you have a 15% chance to get 'Frost Haze' buff (yes, for those who use TellMeWhen addon, you have to type in 'Frost Haze' to display frost procs, typing 'Rime' won't give you anything), which instantly ends howling wind recovery time and allows you to use this ability for 15 seconds without spending any runes. The trick is that if you have a killing machine and rime procs hanging over you at the same time, howling wind from frost will also be a crit. Ice strike performs much more damage on solo targets than wind, so on solo targets the first to use is ice strike, and then wind. On packs, it's quite possible to do critical damage, the numbers will be big.s",
          "Threat of Thassarian is a skill that makes Frosts hit with one-handed weapons. Without this talent, the left-hand doesn't do damage while using certain skills. It allows you to make melee attacks with both hands, which greatly increases your DPS (up to 40%).",
          "Guile of Gorefiend - we should also explain this one, because some newcomers might get confused. This talent increases your critical damage multiplier, rather than critical hit chance.",
          "Necrosis - this talent, roughly speaking, increases the damage of auto-attacks by 20%. Only this 20% of Necrosis does not count as physical damage, but as shadow magic damage. For example, if you hit for 12k with an auto-attack - 10k of that is physical damage, and 2k is damage from shadow magic.",
        ],
      ],
    },
    {
      titleContent: `PVP Talents`,
      titleType: "strong",
      content: [
        "Let’s take a look at Frost Spec. There is no Howling Wind in the build, but there are Ice Claws. Power generation speed of runes increased due to Ice Shackles and Smell of Blood 1/3, sickness prevents your diseases from being dispelled. Some players can take Tank talents. ",
      ],
    },
    {
      titleContent: `WOTLK Death Knight Talents`,
      titleType: "h2",
      content: [
        "DK is a mile-long character who can deliver excellent DPS with the right rotation, well-matched items, spaced talents, enchants and sockets. In the Unholy spec, there are a lot of reasons to play as a Death Knight: DK looks brutal, interesting Talent trees choices and specializations. Besides solo targeting damage, unholy has the most powerful AOE damage in Wrath of the Lich King.",
      ],
    },
    {
      titleContent: `WOTLK Death Knight Talent Tree/Trees`,
      titleType: "h2",
      content: [
        "If we take the Talent Tree from Frost, there are several sets of talents. The first build is entirely made for ARP (armor penetration rating). After gaining an ARP cap of 90% you won’t have to think about the fact that the boss has any armor. The mechanics of frost DK are such that all talents are made to reduce all sorts of your target characteristics.",
      ],
    },
    {
      titleContent: `What Death Knight spec is best for Raiding?`,
      titleType: "h2",
      content: [
        "Your rotation for unholy DK, like any class in Raiding, always comes down to situational actions. To win most of your fights in 1x1, you need to know at least a little bit about your enemy's class, this allows you to anticipate actions and win duels. ",
      ],
    },
    {
      titleContent: `What spec is best for PVP?`,
      titleType: "h2",
      content: [
        "Unholy DK is rightfully considered to be one of the best classes for PVP due to its large number of abilities that can deal massive damage, protect against incoming damage, and keep you out of troubles. The Unholy Death Knight in Arena has minion, which you also need to control. The mechanics are tied to the presence of runes, which can determine your play style. Due to all of that Unholy DK becomes difficult target to kill, this can bail you out in the arena on numerous occasions. However, Frost DK will be more stable and higher on the DPS list. You can experiment with the Skills calculator to choose the best option.",
      ],
    },
    {
      titleContent: `Best Death Knightspec for soloing`,
      titleType: "h3",
      content: [
        "Frost DK's Skills trees are what made this class famous, as this spec is, according to most gamers, the more advanced for pumping DPS. Having a powerful defense, makes Frost DK a legendary warrior. This is a great spec for solo play. ",
      ],
    },
    {
      titleContent: `Best talents for leveling`,
      titleType: "h3",
      content: [
        "Frost DK has these positive traits:",
        [
          "Frost DK doesn't have an inexhaustible list of diseases, so you have to spam chains and take many plague hits.",
          "You depend on the healer for survivability and can avoid enough damage with ice serpent chains (this is the perfect place to swap your presence of Frost often, just waiting for cooldowns).",
          "Forcing opponents to defend themselves with  ridiculous damage and stopping CC on the healer is also important for staying alive.",
          "If you are open against any caster, make sure you or the healer have disks.",
          "Try to break up with DV procs. They have a 45-second internal cooldown.",
          "Make sure you kill the cleansing totem ASAP. ",
          "Chain ice people behind LoS so their healer can't dispel them. I recommend saving frost runes specifically for this",
        ],
        "You should almost always use talents from the Unholy tree, but players often use abilities from  Frost tree to make it easier to heal the healer's damage and also to improve his mana status (less waste).",
      ],
    },
  ],
};
