import "./SeoContent.scss";

import React from "react";

function SeoContent() {
  return (
    <div className="seo-content">
      <div>
        <p>
          First, a few introductory words. The class mechanics in WotLK are
          something that Blizzard developers rework in every add-on and balance
          in every content patch. Therefore, what was relevant a year ago is
          little to no longer so at this point. Because of this, this guide will
          not be detailed descriptions of talents and specs, but a general
          characteristic of classes in terms of their roles and main features.
          So first - about roles.
        </p>
        <p>There are three main roles that classes play in WOW classic:</p>
        <ul>
          <li>
            Tanking: the main function of a tank is to distract mobs from other
            players;
          </li>
          <li>
            Healing: it is obvious that healers must heal other players if they
            take damage;
          </li>
          <li>
            Damage dealing: even more obvious, but keep in mind that some
            classes hit from a distance (RDD) and some in close combat (MDD);
          </li>
        </ul>
        <p>
          Only through the properly pumped build and Talent trees do many
          players have the opportunity to break into the top ranks not only for
          PvE damage but also for PvP. Section WoW builds for 3.3.5 - will
          briefly describe only the best builds by talents. Why is the build a
          lot of attention in WoW? Yes, because the power of the character and
          his performance will depend on the right talent placement. Although it
          will be influenced by many other factors.
        </p>
        <h2>How do talent points work in WOTLK WoW?</h2>
        <p>
          Each character in WoW has different talents and the combination of
          these will directly affect how powerful your Persian will be in PvP or
          PvP. Build in WoW will also depend on your specialization - another
          name for your Speck. Each player has three specializations - three
          specs, and each of them he can go.
        </p>
        <p>
          You can play and heal all, can be the coolest warrior, can lead the
          entire raid, and play as a tank. Each branch of talent in Lich King is
          responsible for its own direction, but often uses mixed builds, which
          also contributes to one or another characteristic. See what you can do
          with the Skills calculator.
        </p>
        <h2>What are honor talents?</h2>
        <p>
          In the class guides, you can find detailed information about each of
          the specializations of this or that class: the choice of PVP and PVE
          talents, rotation, tips on how to apply charms to equipment, a
          detailed explanation of the mechanics of abilities, a selection of
          addons, necessary macros, equipment sets for each phase of the game.
        </p>
        <h2>WOTLK Talents tree in WOW</h2>
        <p>
          These guides discussed all the nuances and options pumping your
          character so that the newcomers were not only interesting and exciting
          to play almost from the first steps, but everyone felt a kind of
          support from the site.
        </p>
        <p>
          You can and agree with the fact that not only does build-in WoW play
          an important role, its own role in the characteristics of the hero, in
          his clothes, in his skills game. If you want to master your character
          on a more professional level by participating in campaigns - you need
          to read PvE guides on your Persian. If you think that you were created
          more for ranked duels in the arena, and this is a completely different
          species of the game, you should read PvP-Guides. They not only help to
          reveal your character from a different side, but also give a lot of
          advice on how to improve your character.
        </p>
        <p>
          Death Knight - "heroic" class, which can be played from the 55th
          damage. Has a separate chain of starting quests, on completion of
          which is selected from the starting location already with level 58.
          Appeared in the Wrath of the Lich King expansion. Equally good at
          using swords and spell casts. Wields swords, axes, and mace, can not
          use shields. The self-release is popular as a class for solo passing
          the old raids.
        </p>
        <p>
          If we highlight the best talents for PVP and PVE, it is worth saying
          that the best and most interesting talents went to the new, the first
          legendary class - Death Knight. That's why the talents mentioned below
          belong to him. The developers made a new class too strong for every
          player to like. Read more about the talents of this class on our
          Talent calculator.
        </p>
        <h3>PVP talents</h3>
        <p>
          In the class PVP guides, you will find detailed information on how to
          best prepare your character for battles against other players in the
          open world, battlefields, or arenas.
        </p>
        <p>
          Killing Machine (Death Knight Talent) - this is the thing that makes
          the killing machine not so good for us. When attacking in close combat
          you have a chance to get the "Killing Machine" buff, which increases
          the chance to get a critical hit with an ice strike or howling wind up
          to 100%. It has a PPM (proc per minute) system, which means that there
          can be no more than a certain amount of procs per minute. This number
          of procs is determined by the level of the talent. Talent gives
          2/4/4/6/6/8/10 proc per minute, depending on how much the talent is
          pumped.
        </p>
        <h3>Best PVE talents</h3>
        <p>
          Rime (Death Knight Talent) is the best for PVE - when you use the
          destruction, you have a 15% chance of getting the Howling Blast buff,
          which instantly ends Howling Blast's recovery time and allows you to
          use the ability for 15 seconds without using runes. The trick is that
          if you have Killing Machine and Rime procs hanging over you at the
          same time, Howling Blast with Rime will also be critical. I must say
          that if you really want to give good DPS. Frost Strike is much more
          damage on solo targets than Howling Blast, so on solo targets first
          Frost Strike, then Howling Blast. On mobs’ packs you can unload
          Howling Blast as well, the numbers will be big.
        </p>
      </div>
    </div>
  );
}

export default SeoContent;
